import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import JsonTable from '../components/JsonTable';

const TablePage = () => {
  const location = useLocation();
  const { dataUrl, title, columnsToDisplay } = location.state || {};

  return (
    <div>
      <h1>{title}</h1>
      <JsonTable 
        dataUrl={dataUrl} 
        title={title} 
        columnsToDisplay={columnsToDisplay} 
      />
      <Link to="/">Back to Home</Link>
    </div>
  );
};

export default TablePage;