import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import './JsonTable.css';

const JsonTable = ({ dataUrl, title, columnsToDisplay }) => {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataUrl) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(dataUrl);
        setJsonData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dataUrl]);

  const columns = React.useMemo(() => {
    if (jsonData.length === 0) return [];
    let keys = Object.keys(jsonData[0]);

    // Filter keys based on columnsToDisplay only if it's provided and not empty
    if (columnsToDisplay && columnsToDisplay.length > 0) {
      keys = keys.filter(key => columnsToDisplay.includes(key));
    }

    // Ensure 'assetunit' is always first if it exists and is included
    const assetUnitIndex = keys.indexOf('assetunit');
    if (assetUnitIndex > -1) {
      keys.splice(assetUnitIndex, 1);
      keys.unshift('assetunit');
    }

    return keys.map(key => ({
      Header: key,
      accessor: key,
      Cell: ({ value }) => {
        if (typeof value === 'boolean') {
          return value ? <span className="checkmark">✓</span> : <span className="xmark">✗</span>;
        }
        if (key === 'assetunit') {
          return (
            <a href={`https://www.lofty.ai/property_deal/${jsonData.find(item => item.assetunit === value)?.slug}`} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          );
        }
        return value;
      }
    }));
  }, [jsonData, columnsToDisplay]);

  const data = React.useMemo(() => jsonData, [jsonData]);

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {title && <h2>{title}</h2>}
      {jsonData.length > 0 ? (
        <table {...getTableProps()} className="json-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default JsonTable;