import React, { useState } from 'react';

const GlossaryButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const containerStyle = {
    position: 'fixed',
    top: '40px',
    right: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1000,
  };

  const buttonStyle = {
    width: '70px',  // 50% of original size
    height: '70px', // 50% of original size
    borderRadius: '50%',
    backgroundColor: '#6e62f0',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const emojiStyle = {
    fontSize: '40px', // 50% of original size
    lineHeight: '1',
  };

  const textStyle = {
    marginTop: '5px', // Reduce margin to match smaller size
    fontSize: '12px',  // 50% of original size
    fontWeight: 'bold',
    color: '#333',
  };

  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const glossaryTermStyle = {
    marginBottom: '10px',
  };

  const glossaryTermTitleStyle = {
    fontWeight: 'bold',
  };

  const glossaryContent = {
    property: {
      address: "The street address of the property.",
      address_line1: "First line of the property address.",
      address_line2: "Second line of the property address.",
      appreciation: "Increase in the property's value over time.",
      assetCreator: "The entity that created the asset.",
      assetId: "Unique identifier for the asset.",
      assetName: "Name of the asset.",
      assetUnit: "Unique identifier for a specific asset or property.",
      cap_rate: "Capitalization rate for the property.",
      cash_flow: "Net cash flow generated by the property.",
      city: "City where the property is located.",
      closing_costs: "Costs associated with closing a property transaction.",
      closing_date: "Date when the property transaction is closed.",
      coc: "Cash on Cash return for the investment.",
      createdAt: "Date when the property was created in the system.",
      curr_maintenance_reserve: "Current reserve for property maintenance.",
      dataType: "Type of data associated with the property.",
      description: "Description of the property.",
      featured: "Indicates if the property is featured.",
      id: "Unique identifier for the property.",
      insurance: "Cost of property insurance.",
      irr: "Internal Rate of Return for the investment.",
      last_price: "Most recent token price",
      lat: "Latitude coordinate of the property.",
      listing_fee: "Fee charged for listing the property.",
      lng: "Longitude coordinate of the property.",
      llc_admin_fee_upfront: "Upfront administrative fee for LLC.",
      llc_admin_fee_yearly: "Yearly administrative fee for LLC.",
      maintenance_reserve: "Reserve fund for property maintenance.",
      management_fees: "Fees for property management.",
      market: "Market in which the property is located.",
      monthly_loan_repayment: "Monthly payment for loan repayment.",
      monthly_rent: "Monthly rental income from the property.",
      num_sold: "Number of units or properties sold.",
      or_replenishment: "Replenishment of operating reserves.",
      original_starting_date: "Original start date for the property listing.",
      ownerId: "Identifier for the owner of the property.",
      ownerRent: "Rent amount charged by the property owner.",
      participant_app_id: "Identifier for the participant application.",
      projected_annual_cash_flow: "Projected cash flow for the year.",
      projected_annual_return: "Projected annual return on investment.",
      projected_rental_yield: "Projected yield from rental income.",
      property_type: "Type of property (e.g., residential, commercial).",
      reserveOwnerId: "Identifier for the reserve owner of the property.",
      sale_price: "Price at which the property is sold.",
      sellout_date: "Date when the property is fully sold.",
      slug: "URL-friendly version of the property name.",
      sqft: "Square footage of the property.",
      starting_date: "Date when the property was listed.",
      state: "State where the property is located.",
      taxes: "Property taxes and other related taxes.",
      thumbnail: "Thumbnail image of the property.",
      tokens: "Number of tokens associated with the property.",
      total_fees: "Total fees associated with the property.",
      total_investment: "Total amount invested in the property.",
      updates: "Recent updates or changes to the property.",
      utilities: "Costs for all utilities.",
      utilities_water_sewer: "Costs for water and sewer utilities.",
      vacancy_reserve: "Reserve fund for potential vacancies.",
      year_built: "Year when the property was built.",
      zipcode: "ZIP code of the property.",
    },
    pool: {
      admin_app_id: "Identifier for the administrative application.",
      base_asset_id: "Identifier for the base asset in a trading pair.",
      base_balance: "Current balance of the base asset in the pool.",
      base_balance_limit: "Limit for the base asset balance.",
      base_excess_quote_value_market_sum: "Sum of excess quote value for base according to market.",
      base_excess_quote_value_oracle: "Excess quote value for base asset according to oracle.",
      base_side_ownership_market: "Ownership of base side according to market.",
      base_side_ownership_oracle: "Ownership of base side according to oracle.",
      base_side_profitloss_at_sale_market: "Profit/loss of base side at sale according to market.",
      base_side_profitloss_at_sale_oracle: "Profit/loss of base side at sale according to oracle.",
      base_side_shortage_value_market: "Shortage value of base side according to market.",
      base_side_shortage_value_oracle: "Shortage value of base side according to oracle.",
      base_staked_balance: "Amount of base asset staked in the pool.",
      base_staked_difference: "Difference in staked base asset.",
      base_staked_quotient: "Quotient of base staked calculation.",
      base_staked_value_market: "Staked value of base asset according to market.",
      base_staked_value_oracle: "Staked value of base asset according to oracle.",
      base_target_difference: "Difference between current and target base balance.",
      base_target_quotient: "Quotient of base target calculation.",
      excess_base_value_market: "Excess base value according to market.",
      excess_base_value_oracle: "Excess base value according to oracle.",
      lp_token_manager_app_id: "Identifier for the LP token manager application.",
      market_price: "Current market price of the asset.",
      oracle_price: "Price provided by the oracle.",
      overspend_balance: "Amount of overspend in the pool.",
      poolId: "Unique identifier for a liquidity pool.",
      quote_asset_id: "Identifier for the quote asset in a trading pair.",
      quote_balance: "Current balance of the quote asset in the pool.",
      quote_excess_base_value_market_sum: "Sum of excess base value for quote according to market.",
      quote_excess_base_value_oracle_sum: "Sum of excess base value for quote according to oracle.",
      quote_side_ownership_market: "Ownership of quote side according to market.",
      quote_side_ownership_oracle: "Ownership of quote side according to oracle.",
      quote_side_profitloss_at_sale_market: "Profit/loss of quote side at sale according to market.",
      quote_side_profitloss_at_sale_oracle: "Profit/loss of quote side at sale according to oracle.",
      quote_side_shortage_value_market: "Shortage value of quote side according to market.",
      quote_side_shortage_value_oracle: "Shortage value of quote side according to oracle.",
      quote_staked_balance: "Amount of quote asset staked in the pool.",
      quote_staked_difference: "Difference in staked quote asset.",
      quote_staked_quotient: "Quotient of quote staked calculation.",
      quote_symbol: "Symbol representing the quote asset.",
      quote_target_difference: "Difference between current and target quote balance.",
      quote_target_quotient: "Quotient of quote target calculation.",
      rewards_manager_app_id: "Identifier for the rewards manager application.",
      staked_limit_quotient: "Quotient of staked limit calculation.",
      target_base_balance: "Target balance for the base asset in the pool.",
      target_quote_balance: "Target balance for the quote asset in the pool.",
      trading_interface_app_id: "Identifier for the trading interface application.",
    },
  };

  return (
    <>
      <div style={containerStyle}>
        <button style={buttonStyle} onClick={() => setIsOpen(true)}>
          <span style={emojiStyle}>📖</span>
        </button>
        <span style={textStyle}>Glossary</span>
      </div>
      {isOpen && (
        <>
          <div style={overlayStyle} onClick={() => setIsOpen(false)} />
          <div style={popupStyle}>
            <h2>Glossary of Terms</h2>
            <h3>Property Fields</h3>
            {Object.entries(glossaryContent.property).map(([term, definition]) => (
              <div key={term} style={glossaryTermStyle}>
                <span style={glossaryTermTitleStyle}>{term}:</span> {definition}
              </div>
            ))}
            <h3>Pool Fields</h3>
            {Object.entries(glossaryContent.pool).map(([term, definition]) => (
              <div key={term} style={glossaryTermStyle}>
                <span style={glossaryTermTitleStyle}>{term}:</span> {definition}
              </div>
            ))}
            <button onClick={() => setIsOpen(false)}>Close</button>
          </div>
        </>
      )}
    </>
  );
};

export default GlossaryButton;

