import React from 'react';
import { NavLink } from 'react-router-dom';

const sidebarStyles = {
  sidebar: (isCollapsed) => ({
    width: isCollapsed ? '60px' : '220px',
    height: '100vh',
    backgroundColor: '#4e3fec',
    color: 'white',
    padding: '20px 10px 20px 0',
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'width 0.3s ease',
    overflow: 'hidden',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  innerSidebar: (isCollapsed) => ({
    width: isCollapsed ? '50px' : '200px',
    height: '100%',
    transition: 'width 0.3s ease',
    overflow: 'hidden',
  }),
  logo: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '30px',
    whiteSpace: 'nowrap',
  },
  nav: {
    listStyle: 'none',
    padding: 0,
    flexGrow: 1,
  },
  navItem: {
    margin: '10px 0',
    whiteSpace: 'nowrap',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    transition: 'background-color 0.3s',
  },
  activeNavLink: {
    backgroundColor: '#3a47d5',
  },
  collapseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  },
  disclaimer: {
    fontSize: '10px',
    textAlign: 'center',
    padding: '10px',
    color: 'lightgray',
    marginTop: 'auto',
  },
};

const Sidebar = ({ isCollapsed, onToggle }) => {
  return (
    <div style={sidebarStyles.sidebar(isCollapsed)}>
      <div style={sidebarStyles.innerSidebar(isCollapsed)}>
        <button
          style={{
            ...sidebarStyles.collapseButton,
            transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
          onClick={onToggle}
        >
          &#9654;
        </button>
        <div style={sidebarStyles.logo}>
          {!isCollapsed && 'Lofty Query'}
        </div>
        <ul style={sidebarStyles.nav}>
          <li style={sidebarStyles.navItem}>
            <NavLink
              to="/advanced-query-builder"
              style={({ isActive }) => ({
                ...sidebarStyles.navLink,
                ...(isActive ? sidebarStyles.activeNavLink : {})
              })}
            >
              {!isCollapsed && 'Properties Query'}
            </NavLink>
          </li>
        </ul>
        {!isCollapsed && (
          <div style={sidebarStyles.disclaimer}>
            This experimental tool is community-built and unaffiliated with Lofty. Data is updated once per day. No warranty is made so please double-check your results.
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;

