import GlossaryButton from './GlossaryButton';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from './AdvancedQueryBuilderStyles';
import { usePoolsQuery } from '../context/PoolsQueryContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://loftyquery.app';

const PoolsQueryBuilder = () => {
  const { poolsQueryState, setPoolsQueryState, clearPoolsQueryState } = usePoolsQuery();
  const [fields, setFields] = useState(poolsQueryState?.fields || []);
  const [selectedFields, setSelectedFields] = useState(poolsQueryState?.selectedFields || '');
  const [fieldSuggestions, setFieldSuggestions] = useState([]);
  const [whereCondition, setWhereCondition] = useState(poolsQueryState?.whereCondition || '');
  const [whereSuggestions, setWhereSuggestions] = useState([]);
  const [queryResult, setQueryResult] = useState(poolsQueryState?.queryResult || null);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'poolid', direction: 'ascending' }); // Default sort

  useEffect(() => {
    const allFields = [
      // pools fields
      'assetUnit', 'poolId', 'base_asset_id', 'quote_asset_id', 'quote_symbol', 'admin_app_id',
      'lp_token_manager_app_id', 'trading_interface_app_id', 'rewards_manager_app_id',
      // pools_dynamic fields
      'timestamp', 'base_staked_balance', 'base_balance', 'quote_balance', 'quote_staked_balance',
      'target_quote_balance', 'target_base_balance', 'overspend_balance', 'oracle_price',
      'base_balance_limit', 'base_target_difference', 'base_target_quotient', 'quote_staked_difference',
      'base_staked_difference', 'base_staked_quotient', 'staked_limit_quotient',
      'base_excess_quote_value_oracle', 'base_staked_value_oracle', 'base_side_ownership_oracle',
      'base_side_profitloss_at_sale_oracle', 'base_side_shortage_value_oracle', 'market_price',
      'quote_target_difference', 'quote_target_quotient', 'quote_staked_quotient',
      'excess_base_value_oracle', 'quote_excess_base_value_oracle_sum', 'quote_side_ownership_oracle',
      'quote_side_shortage_value_oracle', 'quote_side_profitloss_at_sale_oracle',
      'base_excess_quote_value_market_sum', 'base_staked_value_market', 'base_side_ownership_market',
      'base_side_profitloss_at_sale_market', 'base_side_shortage_value_market',
      'excess_base_value_market', 'quote_excess_base_value_market_sum', 'quote_side_ownership_market',
      'quote_side_shortage_value_market', 'quote_side_profitloss_at_sale_market',
      // property_dynamic fields
      'closing_costs', 'curr_maintenance_reserve', 'cap_rate', 'listing_fee', 'participant_app_id',
      'appreciation', 'llc_admin_fee_upfront', 'maintenance_reserve', 'or_replenishment', 'sale_price',
      'monthly_loan_repayment', 'cash_flow', 'num_sold', 'updates', 'irr', 'monthly_rent',
      'utilities_water_sewer', 'projected_annual_cash_flow', 'total_investment', 'taxes', 'tokens',
      'coc', 'llc_admin_fee_yearly', 'total_fees', 'projected_rental_yield', 'projected_annual_return',
      'utilities', 'current_loan', 'vacancy_reserve', 'management_fees', 'city_transfer_tax',
      'insurance', 'is_occupied'
    ];
    setFields(allFields);
  }, []);

  useEffect(() => {
    setPoolsQueryState({ fields, selectedFields, whereCondition, queryResult });
  }, [fields, selectedFields, whereCondition, queryResult, setPoolsQueryState]);

  const handleFieldInputChange = (event) => {
    const input = event.target.value;
    setSelectedFields(input);
    setError(null);

    const inputFields = input.split(',').map(field => field.trim().toLowerCase());
    const lastField = inputFields[inputFields.length - 1];

    const newSuggestions = fields.filter(field =>
      field.toLowerCase().includes(lastField) &&
      !inputFields.slice(0, -1).map(f => f.toLowerCase()).includes(field.toLowerCase())
    );
    setFieldSuggestions(newSuggestions);
  };

  const handleWhereInputChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/"/g, "'");
    setWhereCondition(sanitizedInput);

    const words = sanitizedInput.split(/\s+/);
    const lastWord = words[words.length - 1].toLowerCase();

    const operators = ['=', '>', '<', '>=', '<=', '<>', 'LIKE', 'IN', 'BETWEEN', 'IS NULL', 'IS NOT NULL', 'AND', 'OR'];
    const newSuggestions = [
      ...fields.filter(field => field.toLowerCase().includes(lastWord)),
      ...operators.filter(op => op.toLowerCase().includes(lastWord))
    ];
    setWhereSuggestions(newSuggestions);
  };

  const handleSuggestionClick = (suggestion, type) => {
    if (type === 'field') {
      const currentFields = selectedFields.split(',').map(field => field.trim());
      currentFields.pop();
      currentFields.push(suggestion);
      setSelectedFields(currentFields.join(', '));
      setFieldSuggestions(fields.filter(field =>
        !currentFields.map(f => f.toLowerCase()).includes(field.toLowerCase())
      ));
    } else if (type === 'where') {
      const words = whereCondition.split(/\s+/);
      words.pop();
      words.push(suggestion);
      setWhereCondition(words.join(' ') + ' ');

      const operators = ['=', '>', '<', '>=', '<=', '<>', 'LIKE', 'IN', 'BETWEEN', 'IS NULL', 'IS NOT NULL', 'AND', 'OR'];
      const newSuggestions = [
        ...fields.filter(field => !words.includes(field)),
        ...operators.filter(op => !words.includes(op))
      ];
      setWhereSuggestions(newSuggestions);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let cleanedFields = selectedFields.split(',').map(field => field.trim()).filter(Boolean);
    // if (!cleanedFields.includes('slug')) {
    //  cleanedFields.push('slug');
    // }
    cleanedFields = cleanedFields.join(',');

    try {
      const response = await axios.get(`${API_BASE_URL}/api/pools-query`, {
        params: {
          fields: cleanedFields,
          where: whereCondition
        }
      });
      setQueryResult(response.data);
      setSortConfig({ key: 'poolid', direction: 'ascending' }); // Ensure sorting by poolId
      setError(null);
    } catch (err) {
      console.error('Error executing query:', err);
      setError('An error occurred while executing the query. Please try again.');
      setQueryResult(null);
    }
  };

  const sortKeys = (keys) => {
    return ['poolid', 'slug', ...keys.filter(key => !['poolid', 'slug'].includes(key))];
  };

  const sortData = (data, sortConfig) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Pools Query Builder</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label htmlFor="select-fields" style={styles.label}>Select Fields (comma-separated):</label>
          <input
            type="text"
            id="select-fields"
            value={selectedFields}
            onChange={handleFieldInputChange}
            placeholder="e.g., assetUnit, poolId, base_balance, market_price, monthly_rent"
            style={styles.input}
            autoComplete="off"
          />
          {fieldSuggestions.length > 0 && (
            <ul style={styles.suggestionList}>
              {fieldSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion, 'field')}
                  style={styles.suggestionItem}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="where-condition" style={styles.label}>Where Condition:</label>
          <input
            type="text"
            id="where-condition"
            value={whereCondition}
            onChange={handleWhereInputChange}
            placeholder="e.g., base_balance > 1000 AND market_price < 100 AND monthly_rent > 2000"
            style={styles.input}
            autoComplete="off"
          />
          {whereSuggestions.length > 0 && (
            <ul style={styles.suggestionList}>
              {whereSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion, 'where')}
                  style={styles.suggestionItem}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button type="submit" style={styles.button}>Execute Query</button>
      </form>
      {error && <p style={styles.error}>{error}</p>}
      {queryResult && (
        <div>
          <h3 style={styles.resultHeader}>Query Results:</h3> Click on a column header to sort
          {queryResult.length > 0 ? (
            <table style={styles.table}>
              <thead>
                <tr>
                  {sortKeys(Object.keys(queryResult[0])).map(key => (
                    <th key={key} style={styles.th} onClick={() => requestSort(key)}>
                      {key} {getSortDirection(key)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortData(queryResult, sortConfig).map((row, index) => (
                  <tr key={index}>
                    {sortKeys(Object.keys(row)).map(key => (
                      <td key={key} style={styles.td}>
                        {key === 'poolid' ? (
                          <Link
                            to={`/pool/${row[key]}`}
                            style={styles.link}
                          >
                            {row[key]}
                          </Link>
                        ) : typeof row[key] === 'boolean' ?
                          (row[key] ? '✓' : '✗') :
                          row[key]?.toString()}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}
      <GlossaryButton />
    </div>
  );
};

export default PoolsQueryBuilder;

