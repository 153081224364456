// File: src/context/PoolsQueryContext.js

import React, { createContext, useState, useContext } from 'react';

const PoolsQueryContext = createContext();

export const PoolsQueryProvider = ({ children }) => {
  const [poolsQueryState, setPoolsQueryState] = useState(null);
  const [propertiesQueryState, setPropertiesQueryState] = useState(null);

  return (
    <PoolsQueryContext.Provider value={{ 
      poolsQueryState, 
      setPoolsQueryState,
      propertiesQueryState,
      setPropertiesQueryState
    }}>
      {children}
    </PoolsQueryContext.Provider>
  );
};

export const usePoolsQuery = () => useContext(PoolsQueryContext);