import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const QueryBuilder = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Encode the query to handle special characters in URLs
    const encodedQuery = encodeURIComponent(query);
    navigate('/query-result', { 
      state: { 
        dataUrl: `https://loftyquery.app/api/query?sql=${encodedQuery}`,
        title: 'Custom Query Result'
      }
    });
  };

  return (
    <div>
      <h2>Custom Query Builder</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your SQL query here..."
          rows="5"
          cols="50"
        />
        <br />
        <button type="submit">Execute Query</button>
      </form>
    </div>
  );
};

export default QueryBuilder;
