import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styles from './AdvancedQueryBuilderStyles';
import { usePoolsQuery } from '../context/PoolsQueryContext';
import GlossaryButton from './GlossaryButton';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://loftyquery.app';

const AdvancedQueryBuilder = () => {
  const { propertiesQueryState, setPropertiesQueryState } = usePoolsQuery();
  const [fields, setFields] = useState(propertiesQueryState?.fields || []);
  const [selectedFields, setSelectedFields] = useState(propertiesQueryState?.selectedFields || '');
  const [fieldSuggestions, setFieldSuggestions] = useState([]);
  const [whereCondition, setWhereCondition] = useState(propertiesQueryState?.whereCondition || '');
  const [whereSuggestions, setWhereSuggestions] = useState([]);
  const [queryResult, setQueryResult] = useState(propertiesQueryState?.queryResult || null);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'assetunit', direction: 'ascending' }); // Default sort

  const poolsFields = new Set([
    'poolId', 'base_asset_id', 'quote_asset_id', 'quote_symbol', 'admin_app_id',
    'lp_token_manager_app_id', 'trading_interface_app_id', 'rewards_manager_app_id'
  ]);

  const poolsDynamicFields = new Set([
    'base_staked_balance', 'base_balance', 'quote_balance', 'quote_staked_balance',
    'target_quote_balance', 'target_base_balance', 'overspend_balance', 'oracle_price',
    'base_balance_limit', 'base_target_difference', 'base_target_quotient', 'quote_staked_difference',
    'base_staked_difference', 'base_staked_quotient', 'staked_limit_quotient',
    'base_excess_quote_value_oracle', 'base_staked_value_oracle', 'base_side_ownership_oracle',
    'base_side_profitloss_at_sale_oracle', 'base_side_shortage_value_oracle', 'market_price',
    'quote_target_difference', 'quote_target_quotient', 'quote_staked_quotient',
    'excess_base_value_oracle', 'quote_excess_base_value_oracle_sum', 'quote_side_ownership_oracle',
    'quote_side_shortage_value_oracle', 'quote_side_profitloss_at_sale_oracle',
    'base_excess_quote_value_market_sum', 'base_staked_value_market', 'base_side_ownership_market',
    'base_side_profitloss_at_sale_market', 'base_side_shortage_value_market',
    'excess_base_value_market', 'quote_excess_base_value_market_sum', 'quote_side_ownership_market',
    'quote_side_shortage_value_market', 'quote_side_profitloss_at_sale_market'
  ]);

  useEffect(() => {
    const allFields = [
      // properties fields
      'assetUnit', 'id', 'assetCreator', 'address_line1', 'address_line2', 'address', 'city', 'state', 'zipcode',
      'market', 'property_type', 'assetName', 'thumbnail', 'description', 'year_built', 'createdAt',
      'original_starting_date', 'starting_date', 'available_date', 'closing_date', 'lease_begins_date',
      'sellout_date', 'featured', 'slug', 'sqft', 'lat', 'lng', 'assetId', 'ownerId', 'ownerRent',
      'reserveOwnerId', 'dataType','last_price',
      // property_dynamic fields
      'closing_costs', 'curr_maintenance_reserve', 'cap_rate', 'listing_fee', 'participant_app_id',
      'appreciation', 'llc_admin_fee_upfront', 'maintenance_reserve', 'or_replenishment', 'sale_price',
      'monthly_loan_repayment', 'cash_flow', 'num_sold', 'updates', 'irr', 'monthly_rent',
      'utilities_water_sewer', 'projected_annual_cash_flow', 'total_investment', 'taxes', 'tokens',
      'coc', 'llc_admin_fee_yearly', 'total_fees', 'projected_rental_yield', 'projected_annual_return',
      'utilities', 'current_loan', 'vacancy_reserve', 'management_fees', 'city_transfer_tax',
      'insurance', 'is_occupied', 'timestamp',
      // pools fields
      ...poolsFields,
      // pools_dynamic fields
      ...poolsDynamicFields
    ];
    setFields(allFields);
  }, []);

  useEffect(() => {
    setPropertiesQueryState({ fields, selectedFields, whereCondition, queryResult });
  }, [fields, selectedFields, whereCondition, queryResult, setPropertiesQueryState]);



const handleFieldInputChange = (event) => {
  const input = event.target.value;
  setSelectedFields(input);
  setError(null);

  const inputFields = input.split(',').map(field => field.trim().toLowerCase());
  const lastField = inputFields[inputFields.length - 1];

  // Suppress suggestions if the user has not typed any word (i.e., it's just empty or whitespace)
  if (!lastField || lastField === '') {
    setFieldSuggestions([]);
    return;
  }

  const newSuggestions = fields.filter(field =>
    field.toLowerCase().startsWith(lastField) && // Use startsWith to suggest fields that start with the input
    !inputFields.slice(0, -1).map(f => f.toLowerCase()).includes(field.toLowerCase())
  );
  setFieldSuggestions(newSuggestions);
};

const handleWhereInputChange = (event) => {
  const input = event.target.value;
  const sanitizedInput = input.replace(/"/g, "'");
  setWhereCondition(sanitizedInput);

  const words = sanitizedInput.split(/\s+/);
  const lastWord = words[words.length - 1].toLowerCase();

  // Suppress suggestions if the user has not typed any word (i.e., it's just empty or whitespace)
  if (!lastWord || lastWord === '') {
    setWhereSuggestions([]);
    return;
  }

  const operators = ['=', '>', '<', '>=', '<=', '<>', 'LIKE', 'IN', 'BETWEEN', 'IS NULL', 'IS NOT NULL', 'AND', 'OR'];
  const newSuggestions = [
    ...fields.filter(field => field.toLowerCase().startsWith(lastWord)), // Use startsWith for filtering
    ...operators.filter(op => op.toLowerCase().startsWith(lastWord))
  ];
  setWhereSuggestions(newSuggestions);
};

  const handleSuggestionClick = (suggestion, type) => {
    if (type === 'field') {
      const currentFields = selectedFields.split(',').map(field => field.trim());
      currentFields.pop();
      currentFields.push(suggestion);
      setSelectedFields(currentFields.join(', '));
      setFieldSuggestions(fields.filter(field =>
        !currentFields.map(f => f.toLowerCase()).includes(field.toLowerCase())
      ));
    } else if (type === 'where') {
      const words = whereCondition.split(/\s+/);
      words.pop();
      words.push(suggestion);
      setWhereCondition(words.join(' ') + ' ');

      const operators = ['=', '>', '<', '>=', '<=', '<>', 'LIKE', 'IN', 'BETWEEN', 'IS NULL', 'IS NOT NULL', 'AND', 'OR'];
      const newSuggestions = [
        ...fields.filter(field => !words.includes(field)),
        ...operators.filter(op => !words.includes(op))
      ];
      setWhereSuggestions(newSuggestions);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let cleanedFields = selectedFields.split(',').map(field => field.trim()).filter(Boolean);

    // Ensure 'thumbnail' is always included as the first field and 'slug' is always included
    if (!cleanedFields.includes('thumbnail')) {
      cleanedFields.unshift('thumbnail');
    }
    if (!cleanedFields.includes('assetName')) {
      cleanedFields.push('assetName');
    }

    cleanedFields = cleanedFields.join(',');

    try {
      const selectedFieldsArray = cleanedFields.split(',');
      const usePoolsQuery = selectedFieldsArray.some(field => poolsFields.has(field) || poolsDynamicFields.has(field)) ||
                            whereCondition.split(/\s+/).some(word => poolsFields.has(word) || poolsDynamicFields.has(word));

      const response = await axios.get(`${API_BASE_URL}/api/${usePoolsQuery ? 'pools-query' : 'query'}`, {
        params: {
          fields: cleanedFields,
          where: whereCondition
        }
      });
      setQueryResult(response.data);
      setSortConfig({ key: 'assetunit', direction: 'ascending' }); // Ensure sorting by assetunit
      setError(null);
    } catch (err) {
      console.error('Error executing query:', err);
      setError('An error occurred while executing the query. Please check the query and try again.');
      setQueryResult(null);
    }
  };

  const sortKeys = (keys) => {
    // Ensure 'thumbnail' is always first but make it unsortable and suppress its header
    return ['thumbnail', 'assetname', ...keys.filter(key => !['thumbnail', 'assetname'].includes(key))];
  };

  const requestSort = (key) => {
    // Prevent sorting by 'thumbnail'
    if (key === 'thumbnail') return;

    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  const getHeaderLabel = (key) => {
    // Suppress the thumbnail column header by returning an empty string
    return key === 'thumbnail' ? '' : key;
  };

  const renderCellContent = (key, value) => {
    if (key === 'thumbnail') {
      return (
        <img
          src={value}
          alt="Property Thumbnail"
          style={{ width: '100px', height: 'auto', objectFit: 'cover' }}
        />
      );
    } else if (key === 'assetunit') {
      return (
        <a
          href={`https://www.lofty.ai/property_deal/${value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={styles.link}
        >
          {value}
        </a>
      );
    } else if (key === 'poolid') {
      return (
        <Link to={`/pool/${value}`} style={styles.link}>
          {value}
        </Link>
      );
    } else if (typeof value === 'boolean') {
      return value ? '✓' : '✗';
    } else {
      return value?.toString();
    }
  };

 // const sortData = (data, sortConfig) => {
  //  if (!sortConfig.key) return data;
//
 //   return [...data].sort((a, b) => {
  //    if (a[sortConfig.key] < b[sortConfig.key]) {
   //     return sortConfig.direction === 'ascending' ? -1 : 1;
    //  }
     // if (a[sortConfig.key] > b[sortConfig.key]) {
   //     return sortConfig.direction === 'ascending' ? 1 : -1;
    //  }
    //  return 0;
  //  });
  //};
const sortData = (data, sortConfig) => {
  if (!sortConfig.key) return data;

  return [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    // Detect if values are numeric
    const isNumeric = !isNaN(aValue) && !isNaN(bValue);

    if (isNumeric) {
      // Sort numerically if both values are numeric
      return sortConfig.direction === 'ascending'
        ? aValue - bValue
        : bValue - aValue;
    } else {
      // Sort as strings if values are not numeric
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    }
  });
};


const generateCSV = () => {
  if (!queryResult || queryResult.length === 0) return;

  // Get headers from the first row, excluding 'thumbnail'
  const headers = Object.keys(queryResult[0]).filter(key => key !== 'thumbnail');

  const csvContent = [
    headers.join(','), // Create CSV header row, excluding 'thumbnail'
    ...queryResult.map(row =>
      headers.map(header => {
        let cell = row[header];
        // Handling special cases (e.g., commas, quotes in the content)
        if (typeof cell === 'string' && (cell.includes(',') || cell.includes('"') || cell.includes('\n'))) {
          cell = `"${cell.replace(/"/g, '""')}"`;
        }
        return cell;
      }).join(',')
    )
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'query_results.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};


  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Properties Query</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label htmlFor="select-fields" style={styles.label}>Show Fields (comma-separated):</label>
          <input
            type="text"
            id="select-fields"
            value={selectedFields}
            onChange={handleFieldInputChange}
            placeholder="e.g., city, last_price, monthly_rent"
            style={styles.input}
            autoComplete="off"
          />
          {fieldSuggestions.length > 0 && (
            <ul style={styles.suggestionList}>
              {fieldSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion, 'field')}
                  style={styles.suggestionItem}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div style={styles.inputGroup}>
          <label htmlFor="where-condition" style={styles.label}>Where:</label>
          <input
            type="text"
            id="where-condition"
            value={whereCondition}
            onChange={handleWhereInputChange}
            placeholder="e.g., state = 'OH' AND year_built > 2000 AND last_price < 30"
            style={styles.input}
            autoComplete="off"
          />
          {whereSuggestions.length > 0 && (
            <ul style={styles.suggestionList}>
              {whereSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion, 'where')}
                  style={styles.suggestionItem}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <button type="submit" style={styles.button}>Execute Query</button>
      </form>
      {error && <p style={styles.error}>{error}</p>}
      {queryResult && (
        <div>
          <h3 style={styles.resultHeader}>Query Results:</h3> Click on a column header to sort
          {queryResult.length > 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <button onClick={generateCSV} style={{ ...styles.button, marginBottom: '10px' }}>Export to CSV</button>
              <table style={styles.table}>
                <thead>
                  <tr>
                    {sortKeys(Object.keys(queryResult[0])).map(key => (
                      <th key={key} style={styles.th} onClick={() => requestSort(key)}>
                        {getHeaderLabel(key)} {getSortDirection(key)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {sortData(queryResult, sortConfig).map((row, index) => (
                    <tr key={index}>
                      {sortKeys(Object.keys(row)).map(key => (
                        <td key={key} style={styles.td}>
                          {renderCellContent(key, row[key])}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available</p>
          )}
        </div>
      )}
      <GlossaryButton/>
    </div>
  );
};

export default AdvancedQueryBuilder;

